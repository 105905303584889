<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <h4>欢迎来到zcx的个人主页</h4>
    <div style="height:30vh"></div>
    <a href="https://beian.miit.gov.cn/">备案号：粤ICP备2022052427号-1</a>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
}
</script>
